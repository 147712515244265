"use client";
import AscBannerAnimation from "public/landing/asc-animation.gif";
import Image from "next/image";
import { useEffect, useMemo, useState } from "react";
import formatDollar from "utils/formatDollar";
import ButtonV2 from "./ButtonV2";
import { usePathname, useRouter } from "next/navigation";
import { useAppealContext } from "context/AppealContext";
import { useEventTracker } from "utils/useEventTracker";
import constants from "utils/constants";
import mixpanel from "mixpanel-browser";

const LOW_SAVINGS_THRESHOLD = 100;

const AscBanner = () => {
	const { __setHydrating } = useAppealContext();
	const router = useRouter();
	const trackEvent = useEventTracker();

	const [showAscBanner, setShowAscBanner] = useState(false);
	const [persistentAppealData, setPersistentAppealData] = useState<any>({});
	const [trackedSeen, setTrackedSeen] = useState(false);

	const pathname = usePathname();

	const commercialStyling = useMemo(
		() => ["/commercial"].includes(pathname),
		[pathname]
	);

	useEffect(() => {
		const handler = () => {
			const data = window.localStorage.getItem("persistentAppealData");

			const parsed = data ? JSON.parse(data) : {};

			setShowAscBanner(
				parsed &&
					parsed.address &&
					parsed.savingsData &&
					parsed.savingsData?.inService
			);

			setPersistentAppealData({
				address: parsed?.address?.address,
				savings: parsed?.savingsData?.amt,
				first_name: parsed?.contactInfo?.firstName,
				email: parsed?.contactInfo?.email,
			});
		};

		handler();

		window.addEventListener("persistentAppealData", handler);

		return () => {
			window.removeEventListener("persistentAppealData", handler);
		};
	}, []);

	useEffect(() => {
		if (showAscBanner && !trackedSeen) {
			trackEvent({
				eventName: constants.EVENTS.ASC_BANNER_SEEN,
				data: {
					savings: persistentAppealData.savings,
					address: persistentAppealData.address,
				},
			});

			setTrackedSeen(true);
		}
	}, [showAscBanner, persistentAppealData, trackEvent, trackedSeen]);

	const title = useMemo(() => {
		if (
			!persistentAppealData.address ||
			[null, undefined].includes(persistentAppealData.savings)
		) {
			return "Finish signing up to save on property taxes.";
		}

		const addressCopy = commercialStyling
			? "your properties"
			: persistentAppealData.address;

		const cta =
			(persistentAppealData.savings ?? 0) <= LOW_SAVINGS_THRESHOLD
				? "for savings on property taxes."
				: `for ${formatDollar(persistentAppealData.savings)} in estimated savings.`;

		if (persistentAppealData.first_name && persistentAppealData.email) {
			return `${persistentAppealData.first_name}, only 1 step left! Finish signing up ${addressCopy} ${cta}`;
		}

		if (persistentAppealData.first_name) {
			return `${persistentAppealData.first_name}, finish signing up ${addressCopy} ${cta}`;
		}

		return `Finish signing up ${addressCopy} ${cta}`;
	}, [persistentAppealData, commercialStyling]);

	const onNoThanks = () => {
		if (typeof window === "undefined") return;
		window.localStorage.removeItem("persistentAppealData");
		setShowAscBanner(false);
		trackEvent({
			eventName: constants.EVENTS.ASC_BANNER_CLICKED,
			data: {
				cta: "No Thanks",
				savings: persistentAppealData.savings,
				address: persistentAppealData.address,
			},
		});
	};

	const onContinue = () => {
		if (typeof window === "undefined") return;

		const stored = window.localStorage.getItem("persistentAppealData");

		if (!stored) return;

		window.sessionStorage.setItem("appealData", stored);

		let href;

		if (
			persistentAppealData.address &&
			persistentAppealData.savings &&
			persistentAppealData.first_name &&
			persistentAppealData.email
		) {
			href = "/appeal/legal";
		} else if (
			persistentAppealData.address &&
			persistentAppealData.savings &&
			persistentAppealData.first_name
		) {
			href = "/appeal/contact";
		} else if (persistentAppealData.address) {
			href = "/appeal/savings";
		} else {
			href = "/appeal";
		}

		__setHydrating(true);

		router.push(href);

		mixpanel.register({
			came_from_asc_banner: true,
		});

		trackEvent({
			eventName: constants.EVENTS.ASC_BANNER_CLICKED,
			data: {
				cta: "Continue",
				savings: persistentAppealData.savings,
				address: persistentAppealData.address,
			},
		});
	};

	if (!showAscBanner) return null;

	return (
		<div
			id={"asc-banner" + (commercialStyling ? "-commercial" : "")}
			className={commercialStyling ? "asc-banner-commercial" : ""}>
			<div className="flex items-center">
				<Image id="animation" src={AscBannerAnimation} alt="Coin Spinning" />
				<div id="asc-banner-content">
					<div id="asc-banner-text">
						<p className="h5">{title}</p>
						<p className="sm mt-1">
							Pick up where you left off and never overpay on property taxes
							again.
						</p>
					</div>
					<div id="asc-banner-btn-group">
						<ButtonV2
							size="small"
							variant="primary-outline"
							onClick={onNoThanks}>
							No Thanks
						</ButtonV2>
						<ButtonV2 size="small" onClick={onContinue}>
							Continue
						</ButtonV2>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AscBanner;
