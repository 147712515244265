"use client";
import { FC, useMemo } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Script from "next/script";
import AnnouncementBanner from "elements/AnnouncementBanner";
import { usePathname } from "next/navigation";
import RealtorReferralPartnerBanner from "components/pages/Landing/RealtorReferralPartnerBanner";
import { PartnersBanner } from "components/pages/Landing/PartnersBanner";
import AscBanner from "elements/AscBanner";
import moment from "moment-timezone";

const Layout: FC<{ banners?: React.ReactNode }> = ({ children, banners }) => {
	const pathname = usePathname();

	const showAnnouncementBanner = false;

	const hiddenChatBotPaths = ["/lp/welcome"];

	const isBusinessHours = () => {
		// Get current time in CST/Chicago timezone
		const currentTime = moment().tz("America/Chicago");
		const hours = currentTime.hours();
		const day = currentTime.day();

		// Check if time is between Mon-Fri 9 AM and 5 PM
		return day > 0 && day < 6 && hours >= 9 && hours < 17;
	};

	return (
		<>
			{/* <style jsx global>
				{`
					body {
						background-color: #edf6ff;
					}
				`}
			</style> */}
			{!hiddenChatBotPaths.includes(pathname) && isBusinessHours() && (
				<Script id="genesys-chatbot" strategy="afterInteractive">
					{`
                      (function (g, e, n, es, ys) {

                        g['_genesysJs'] = e;

                        g[e] = g[e] || function () {

                          (g[e].q = g[e].q || []).push(arguments)

                        };

                        g[e].t = 1 * new Date();

                        g[e].c = es;

                        ys = document.createElement('script'); ys.async = 1; ys.src = n; ys.charset = 'utf-8'; document.head.appendChild(ys);

                      })(window, 'Genesys', 'https://apps.usw2.pure.cloud/genesys-bootstrap/genesys.min.js', {

                        environment: 'prod-usw2',

                        deploymentId: 'b619ee64-8a9a-44ac-81c6-d1539d280889'

                      });
                    `}
				</Script>
			)}
			<div
				id="site-layout"
				className={[showAnnouncementBanner ? "fancy-w-banner" : "fancy"].join(
					" "
				)}>
				<Header>{showAnnouncementBanner && <AnnouncementBanner />}</Header>
				<div id="site-banners">{banners}</div>
				<main id="site-body">{children}</main>
				<AscBanner />
				<Footer />
			</div>
		</>
	);
};

export default Layout;
